export default [
  {
    title: 'Home',
    i18n: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    roles: ['SuperAdmin', 'operator', 'admin', 'aviaseller', 'cashier', 'touroperator', 'visaseller', 'visaadmin'],
  },
  {
    title: 'Reports',
    i18n: 'Reports',
    icon:'LayersIcon',
    route: null,
    roles: ['SuperAdmin', 'admin', 'visaadmin'],
    children: [
      {
        title:'Tour Reports',
        i18n:"TourReports",
        route:"TourReports",
        icon:"BarChartIcon",
        roles: ['SuperAdmin', 'admin'],
      },
      {
        title: 'Reports',
        i18n: 'BranchReports',
        route: 'BranchReports',
        icon: 'BarChart2Icon',
        roles: ['SuperAdmin', 'admin'],
      },
      {
        title: 'Avia Tickets Reports',
        i18n: 'AviaTicketReports',
        route: 'AviaTicketReports',
        icon: 'BarChart2Icon',
        roles: ['SuperAdmin', 'admin'],
      },
      {
        title: 'Insurance Reports',
        i18n: 'InsuranceReports',
        route: 'InsuranceReports',
        icon: 'BarChartIcon',
        roles: ['SuperAdmin', 'admin'],
      },
      {
        title: 'Visa Reports',
        i18n: 'VisaReports',
        route: 'VisaReports',
        icon: 'BarChartIcon',
        roles: ['SuperAdmin', 'admin', 'visaadmin'],
      },
      {
        title: 'Payment Reports',
        i18n: 'PaymentReports',
        route: 'PaymentReports',
        icon: 'BarChart2Icon',
        roles: ['SuperAdmin', 'admin'],
      },
      {
        title: 'OperatorDailyReports',
        i18n: 'OperatorDailyReports',
        route: 'OperatorDailyReports',
        icon: 'BarChart2Icon',
        roles: ['SuperAdmin'],
      },
      {
        title: 'IncomesAndOutcomes',
        i18n: 'IncomesAndOutcomes',
        route: 'IncomesAndOutcomes',
        icon: 'BarChart2Icon',
        roles: ['SuperAdmin'],
      },

    ]
  },
  // {
  //   title:"Profile",
  //   i18n: 'Profile',
  //   route:'profile',
  //   icon: 'UserIcon',
  //   roles: ['SuperAdmin', 'operator', 'admin', 'aviaseller', 'cashier', 'touroperator'],
  // },
  {
    title: 'Clients',
    i18n: 'Clients',
    icon:"UsersIcon",
    route: 'DirectoriesClients',
    roles: ['SuperAdmin','operator', 'touroperator', 'aviaseller', 'visaseller', 'visaadmin'],
  },
  {
    title:"Tours",
    i18n: 'Tours',
    route: 'tours',
    icon: 'BriefcaseIcon',
    roles: ['SuperAdmin', 'operator', 'touroperator'],
  },
  {
    title:"Avia Tickets Sales",
    i18n: 'Avia Tickets',
    route: 'avia-tickets-sales',
    icon: 'GlobeIcon',
    roles: ['SuperAdmin', 'aviaseller'],
  },
  {
    title:"Visa",
    i18n: 'Visa',
    route: 'visa',
    icon: 'CreditCardIcon',
    roles: ['SuperAdmin', 'touroperator', 'visaseller', 'visaadmin'],
  },
  {
    title:"Insurance",
    i18n: 'Insurance',
    route: 'insurance',
    icon: 'ShieldIcon',
    roles: ['SuperAdmin', 'touroperator'],
  },
  {
    title: 'Directories',
    i18n: 'Directories',
    icon:'FolderIcon',
    route: null,
    roles: ['SuperAdmin','admin', 'operator', 'touroperator' ,'aviaseller', 'cashier', 'visaseller', 'visaadmin'],
    children: [
      {
        title: 'Branchs',
        i18n: 'Branchs',
        icon:"ListIcon",
        route: 'DirectoriesBranchs',
        roles: ['SuperAdmin', 'visaadmin'],
      },
      {
        title: 'Roles',
        i18n: 'Roles',
        icon:"FilterIcon",
        route: 'DirectoriesRoles',
        roles: ['SuperAdmin'],
      },
      {
        title:'Expense Types',
        i18n: 'Expense_types',
        icon:"CreditCardIcon",
        route: 'ExpenseType',
        roles: ['SuperAdmin', 'cashier'],
      },
      // {
      //   title:'Customer Source',
      //   i18n: 'Customer_source',
      //   icon:"BarChartIcon",
      //   route:'CustomerSource',
      //   roles: ['SuperAdmin'],
      // },
      // {
      //   title:'Payment Method',
      //   i18n: 'Payment_method',
      //   icon:"InboxIcon",
      //   route:'PaymentMethod',
      //   roles: ['SuperAdmin'],
      // },
      {
        title:'Partners',
        i18n: 'Partners',
        icon:"PhoneIcon",
        route:'Partners',
        roles: ['SuperAdmin','operator','admin', 'touroperator' , 'aviaseller', 'cashier', 'visaadmin', 'visaseller'],
      },
      {
        title:'Country',
        i18n: 'Countries',
        icon: "MapIcon",
        route:'Country',
        roles: ['SuperAdmin','operator','admin', 'touroperator', 'aviaseller', 'visaadmin', 'visaseller'],
      },
      // {
      //   title:'Regions',
      //   i18n: 'Regions',
      //   icon: "MapPinIcon",
      //   route:'Regions',
      //   roles: ['SuperAdmin','operator','admin', 'visaadmin', 'visaseller'],
      // },
      {
        title:'Users',
        i18n: 'Users',
        icon:"UserIcon",
        route:'Users',
        roles: ['SuperAdmin', 'visaadmin'],
      },
      {
        title:"Hotels",
        i18n: 'Hotels',
        route:'Hotels',
        icon: 'ListIcon',
        roles: ['SuperAdmin','operator','admin', 'touroperator'],
      },
      // {
      //   title:'Avia Tickets',
      //   i18n: "Avia Tickets",
      //   route: "Tickets",
      //   icon: "GlobeIcon",
      //   roles: ['SuperAdmin'],
      // },
      {
        title: 'Client Sources',
        i18n: 'ClientSources',
        route: 'ClientSources',
        icon: 'BarChart2Icon',
        roles: ['SuperAdmin', 'touroperator', 'aviaseller'],
      },
      {
        title: 'avia-companies',
        i18n: 'AviaCompanies',
        route: 'avia-companies',
        icon: 'ListIcon',
        roles: ['SuperAdmin', 'aviaseller'],
      },
      {
        title: 'Visa list',
        i18n: '',
        route: 'CountryVisasList',
        icon: 'ListIcon',
        roles: ['SuperAdmin', 'visaadmin', 'visaseller'],
      },
      {
        title: 'Currencies',
        i18n: '',
        route: 'CurrencyIndex',
        icon: 'ListIcon',
        roles: ['SuperAdmin', 'visaadmin', 'visaseller'],
      },
      {
        title: 'Visa VAT',
        i18n: '',
        route: 'VisaVATIndex',
        icon: 'ListIcon',
        roles: ['SuperAdmin', 'visaadmin', 'visaseller'],
      },
    ],
  },
  {
    title: "Payments",
    i18n: 'Payments',
    route: 'Payments',
    icon: "DollarSignIcon",
    roles: ['SuperAdmin', 'cashier',]
  },
  {
    title: "Contracts",
    i18n: 'Contracts',
    route: 'Contracts',
    icon: "ClipboardIcon",
    roles: ['SuperAdmin', 'cashier']
  },
  {
    title: "PaymentExpence",
    i18n: 'PaymentExpence',
    route: 'PaymentExpence',
    icon: "ClipboardIcon",
    roles: ['SuperAdmin', 'cashier']
  },
  {

    title: 'Collection',
    i18n: 'Collection',
    route: null,
    icon:'DollarSignIcon',
    roles: ['SuperAdmin', 'cashier'],
    children: [
      {
        title:'Tours',
        i18n: "Tours",
        route: "CollectionTours",
        icon: "GlobeIcon",
        roles: ['SuperAdmin', 'cashier'],
      },
      {
        title:'Avia Tickets',
        i18n: "Avia Tickets",
        route: "CollectionTicket",
        icon: "GlobeIcon",
        roles: ['SuperAdmin', 'cashier'],
      },
      {
        title:'Insurance',
        i18n: "Insurance",
        route: "CollectionInsurance",
        icon: "GlobeIcon",
        roles: ['SuperAdmin', 'cashier'],
      },
    ]
  },
]
